import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import colors from "../../../utils/colors";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from "@material-ui/core";
import AvailableAssessmentsTable from "./AvailableAssessmentsTable";

const theme = createMuiTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        left: "48%",
        position: "absolute",
        bottom: "10px",
      },
      svg: {
        color: colors.baseBlue,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    // minWidth: 500,
  },
  checkBox: {
    margin: theme.spacing(1),
    marginBottom: 0,
  },
  right: {
    marginLeft: "auto",
  },
  cancelButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  searchButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
  openButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
}));

function AssessmentSelectionDialog({
  open,
  handleClose,
  handleOpenAssessment,
  assessments,
  selectedAssessment,
  setSelectedAssessment,
}) {
  const classes = useStyles();

  const handleResetAndClose = () => {
    setSelectedAssessment(null);
    handleClose();
  };

  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={handleResetAndClose}
        aria-labelledby="services-dialog"
        scroll="paper"
      >
        <DialogTitle id="services-dialog-title">
          Assessment Selection
        </DialogTitle>
        <DialogContent>
          {/* <form onSubmit={handleSearchServices}> */}

          {/* </form> */}
          {/* {isSearching ? (
            <ThemeProvider theme={theme}>
              <CircularProgress />
            </ThemeProvider>
          ) : null} */}
          <AvailableAssessmentsTable
            assessments={assessments}
            selectedAssessment={selectedAssessment}
            setSelectedAssessment={setSelectedAssessment}
          />
          <FormGroup row>
            <Button
              color="primary"
              type="submit"
              disabled={!selectedAssessment}
              className={classes.openButton}
              onClick={handleOpenAssessment}
            >
              Open
            </Button>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleResetAndClose}
            color="primary"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AssessmentSelectionDialog.propTypes = {
  assessments: PropTypes.array.isRequired,
  selectedAssessment: PropTypes.any.isRequired,
  setSelectedAssessment: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpenAssessment: PropTypes.func.isRequired,
};

export default AssessmentSelectionDialog;
