import ACL, { ALL_WILDCARD, EDIT, MENU, VIEW, ADD, DELETE } from "./ACL";

class ACLBase {}

class NotesACLs extends ACLBase {
  ALL;
  VIEW;
  ADD;
}

class CareTeamACLs extends ACLBase {
  ALL;
  VIEW;
  ADD;
  EDIT;
  MEMBERS;
}

class TaskACLs extends ACLBase {
  ALL;
  VIEW;
  ADD;
  EDIT;
  ASSIGN_PROGRAM;
  NOTES;
}

class AttachmentsACLs extends ACLBase {
  ALL;
  VIEW;
  ADD;
  EDIT;
}

const getACLs = (acls) => acls;

//Admin ACLs
const adminACL = new ACL("admin");
//Admin Users ACLs
const adminUsersACL = adminACL.newChild("users");

//Admin Permissions ACLs
const adminPermissionsACL = adminACL.newChild("permissions");

//CRS ACLs
const mainACL = new ACL("main");

//CRS Dashboard
const crsDashboardACL = mainACL.newChild("dashboard");

//CRS Patient ACLs
const crsPatientACL = mainACL.newChild("patient");
const crsPatientSearchACL = crsPatientACL.newChild("search");

const crsPatientAssessmentsACL = crsPatientACL.newChild("assessments");
const mainPatientTabsACL = crsPatientACL.newChild("tabs");
const crsPatientCasesACL = crsPatientACL.newChild("cases");
const crsPatientCarePlanACL = crsPatientACL.newChild("carePlan");
const crsPatientServiceRequestACL = crsPatientACL.newChild("serviceRequest");
const crsPatientCareTeamsACL = crsPatientACL.newChild("careTeams");
const crsPatientCareTeamsMembersACL =
  crsPatientCareTeamsACL.newChild("members");
const crsPatientAttachmentsACL = crsPatientACL.newChild("attachments");
const crsPatientNotesACL = crsPatientACL.newChild("notes");
const crsPatientTasksACL = crsPatientACL.newChild("tasks");
const crsPatientTasksNotesACL = crsPatientTasksACL.newChild("tasks");

//CRS Referral
const mainReferralACL = mainACL.newChild("referral");
const crsReferralIdentifierDetailsACL =
  mainReferralACL.newChild("identifierDetails");

//CRS Case
const crsCaseACL = mainACL.newChild("case");
const crsCaseGoalACL = crsCaseACL.newChild("goal");
const crsCaseGoalNotesACL = crsCaseGoalACL.newChild("notes");
const crsCaseTaskACL = crsCaseACL.newChild("task");
const crsCaseTaskNotesACL = crsCaseTaskACL.newChild("notes");
const crsCaseCareTeamsACL = crsCaseACL.newChild("careTeams");
const crsCaseCareTeamsMembersACL = crsCaseCareTeamsACL.newChild("members");
const crsCaseContactAttemptsACL = crsCaseACL.newChild("contactAttempts");
const crsCaseNotesACL = crsCaseACL.newChild("notes");
const crsCaseAttachmentsACL = crsCaseACL.newChild("attachments");

//CRS Task
const crsTaskACL = mainACL.newChild("task");

const mainAcls = {
  ALL: ALL_WILDCARD,
  ADMIN: {
    ALL: adminACL.concat(ALL_WILDCARD),
    USERS: {
      ALL: adminUsersACL.concat(ALL_WILDCARD),
      MENU: adminUsersACL.concat(MENU),
      VIEW: adminUsersACL.concat(VIEW),
      ADD: adminUsersACL.concat(ADD),
    },

    PERMISSIONS: {
      ALL: adminPermissionsACL.concat(ALL_WILDCARD),
      MENU: adminPermissionsACL.concat(MENU),
      ADD: adminPermissionsACL.concat(ADD),
      DELETE: adminPermissionsACL.concat(DELETE),
      EDIT: adminPermissionsACL.concat(EDIT),
    },
  },
  MAIN: {
    ALL: mainACL.concat(ALL_WILDCARD),
    DASHBOARD: {
      ALL: crsDashboardACL.concat(ALL_WILDCARD),
      MENU: crsDashboardACL.concat(MENU),
    },
    PATIENT: {
      ALL: crsPatientACL.concat(ALL_WILDCARD),
      MENU: crsPatientACL.concat(MENU),
      ADD: crsPatientACL.concat(ADD),
      VIEW: crsPatientACL.concat(VIEW),
      TABS: {
        ALL: mainPatientTabsACL.concat(ALL_WILDCARD),
        GENERAL: mainPatientTabsACL.concat("general", VIEW),
        DOCUMENTS: mainPatientTabsACL.concat("documents", VIEW),
        ASSESSMENTS: mainPatientTabsACL.concat("assessments", VIEW),
        REFERRALS: mainPatientTabsACL.concat("referrals", VIEW),
        PREVENTION_LINK: mainPatientTabsACL.concat("preventionLink", VIEW),
        CASES: mainPatientTabsACL.concat("cases", VIEW),
      },
      CARD: {
        VIEW: crsPatientACL.concat("card", VIEW),
      },

      TASKS:
        getACLs <
        TaskACLs >
        {
          ALL: crsPatientTasksACL.concat(ALL_WILDCARD),
          VIEW: crsPatientTasksACL.concat(VIEW),
          ADD: crsPatientTasksACL.concat(ADD),
          EDIT: crsPatientTasksACL.concat(EDIT),
          ASSIGN_PROGRAM: crsPatientTasksACL.concat("assignProgram"),
          NOTES:
            getACLs <
            NotesACLs >
            {
              ALL: crsPatientTasksNotesACL.concat(ALL_WILDCARD),
              VIEW: crsPatientTasksNotesACL.concat(VIEW),
              ADD: crsPatientTasksNotesACL.concat(ADD),
            },
        },
      NOTES:
        getACLs <
        NotesACLs >
        {
          ALL: crsPatientNotesACL.concat(ALL_WILDCARD),
          VIEW: crsPatientNotesACL.concat(VIEW),
          ADD: crsPatientNotesACL.concat(ADD),
        },
      ATTACHMENTS:
        getACLs <
        AttachmentsACLs >
        {
          ALL: crsPatientAttachmentsACL.concat(ALL_WILDCARD),
          VIEW: crsPatientAttachmentsACL.concat(VIEW),
          ADD: crsPatientAttachmentsACL.concat(ADD),
          EDIT: crsPatientAttachmentsACL.concat(EDIT),
        },
      CARE_TEAMS:
        getACLs <
        CareTeamACLs >
        {
          ALL: crsPatientCareTeamsACL.concat(ALL_WILDCARD),
          VIEW: crsPatientCareTeamsACL.concat(VIEW),
          ADD: crsPatientCareTeamsACL.concat(ADD),
          EDIT: crsPatientCareTeamsACL.concat(EDIT),
          MEMBERS: {
            ALL: crsPatientCareTeamsMembersACL.concat(ALL_WILDCARD),
            ADD: crsPatientCareTeamsMembersACL.concat(ADD),
            EDIT: crsPatientCareTeamsMembersACL.concat(EDIT),
            DELETE: crsPatientCareTeamsMembersACL.concat(DELETE),
          },
        },
      CASES: {
        ALL: crsPatientCasesACL.concat(ALL_WILDCARD),
        VIEW: crsPatientCasesACL.concat(VIEW),
        EDIT: crsPatientCasesACL.concat(EDIT),
      },
      CARE_PLAN: {
        ALL: crsPatientCarePlanACL.concat(ALL_WILDCARD),
        VIEW: crsPatientCarePlanACL.concat(VIEW),
        EDIT: crsPatientCarePlanACL.concat(EDIT),
      },
      SEARCH: {
        ALL: crsPatientSearchACL.concat(ALL_WILDCARD),
      },
      ASSESSMENTS: {
        ALL: crsPatientAssessmentsACL.concat(ALL_WILDCARD),
        START: crsPatientAssessmentsACL.concat("start"),
        VIEW: crsPatientAssessmentsACL.concat(VIEW),
      },
    },
    REFERRAL: {
      ALL: mainReferralACL.concat(ALL_WILDCARD),
      VIEW: mainReferralACL.concat(VIEW),
      MENU: mainReferralACL.concat(MENU),
    },
    CASE: {
      ALL: crsCaseACL.concat(ALL_WILDCARD),
      VIEW: crsCaseACL.concat(VIEW),
      MENU: crsCaseACL.concat(MENU),
      EDIT: crsCaseACL.concat(EDIT),
      GOAL: {
        ALL: crsCaseGoalACL.concat(ALL_WILDCARD),
        VIEW: crsCaseGoalACL.concat(VIEW),
        ADD: crsCaseGoalACL.concat(ADD),
        EDIT: crsCaseGoalACL.concat(EDIT),
        NOTES:
          getACLs <
          NotesACLs >
          {
            ALL: crsCaseGoalNotesACL.concat(ALL_WILDCARD),
            VIEW: crsCaseGoalNotesACL.concat(VIEW),
            ADD: crsCaseGoalNotesACL.concat(ADD),
          },
      },
      TASKS:
        getACLs <
        TaskACLs >
        {
          ALL: crsCaseTaskACL.concat(ALL_WILDCARD),
          VIEW: crsCaseTaskACL.concat(VIEW),
          ADD: crsCaseTaskACL.concat(ADD),
          EDIT: crsCaseTaskACL.concat(EDIT),
          ASSIGN_PROGRAM: crsCaseTaskACL.concat("assignProgram"),
          NOTES:
            getACLs <
            NotesACLs >
            {
              ALL: crsCaseTaskNotesACL.concat(ALL_WILDCARD),
              VIEW: crsCaseTaskNotesACL.concat(VIEW),
              ADD: crsCaseTaskNotesACL.concat(ADD),
            },
        },
      CARE_TEAMS:
        getACLs <
        CareTeamACLs >
        {
          ALL: crsCaseCareTeamsACL.concat(ALL_WILDCARD),
          VIEW: crsCaseCareTeamsACL.concat(VIEW),
          ADD: crsCaseCareTeamsACL.concat(ADD),
          EDIT: crsCaseCareTeamsACL.concat(EDIT),
          MEMBERS: {
            ALL: crsCaseCareTeamsMembersACL.concat(ALL_WILDCARD),
            ADD: crsCaseCareTeamsMembersACL.concat(ADD),
            EDIT: crsCaseCareTeamsMembersACL.concat(EDIT),
            DELETE: crsCaseCareTeamsMembersACL.concat(DELETE),
          },
        },
      NOTES:
        getACLs <
        NotesACLs >
        {
          ALL: crsCaseNotesACL.concat(ALL_WILDCARD),
          VIEW: crsCaseNotesACL.concat(VIEW),
          ADD: crsCaseNotesACL.concat(ADD),
        },
      ATTACHMENTS:
        getACLs <
        AttachmentsACLs >
        {
          ALL: crsCaseAttachmentsACL.concat(ALL_WILDCARD),
          VIEW: crsCaseAttachmentsACL.concat(VIEW),
          ADD: crsCaseAttachmentsACL.concat(ADD),
          EDIT: crsCaseAttachmentsACL.concat(EDIT),
        },
    },
    TASK: {
      ALL: crsTaskACL.concat(ALL_WILDCARD),
      MENU: crsTaskACL.concat(MENU),
    },
  },
};

const getFlatACLsList = (acls) => {
  const keys = Object.keys(acls);

  return keys.reduce((list, key) => {
    if (typeof acls[key] === "object")
      return [...list, ...getFlatACLsList(acls[key])];

    return [...list, acls[key]];
  }, []);
};

export default mainAcls;
