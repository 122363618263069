import _, { isArray } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { Context } from "../../../store/AppStore";
import styles from "../../../styles/viewer.module.css";
import {
  AssessmentsActionButton,
  AssessmentsActions,
} from "./AssessmentsActions";
import AssessmentsList from "./AssessmentsList";
import NewAssessmentDialog from "./NewAssessmentDialog";
import AssessmentSelectionDialog from "./AssessmentSelectionDialog";
import AssessmentsAPI from "../../../services/AssessmentsAPI";

const AssessmentsViewer = () => {
  const history = useHistory();
  const dispatch = useContext(Context)[1];
  const { patientId } = useParams();
  const [availableAssignments, setAvailableAssessments] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [assessmentSelectionDialogOpen, setAssessmentSelectionDialogOpen] =
    useState(false);
  const [openNewAssessment, setOpenNewAssessment] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);

  const handleAssessmentSelectionDialogClick = () => {
    setAssessmentSelectionDialogOpen(true);
  };

  const handleNewAssessmentNewTabClick = () => {
    window.open(process.env.REACT_APP_TEST_ASSESSMENT, "_blank");
  };

  const handleNewAssessmentSelectionDialogClose = () => {
    setAssessmentSelectionDialogOpen(false);
  };

  const handleOpenNewAssessment = () => {
    handleNewAssessmentSelectionDialogClose();
    setOpenNewAssessment(true);
  };

  const handleCloseNewAssessment = () => {
    setOpenNewAssessment(false);
    handleGetAssessments();
  };

  const handleGetAvailableAssessments = async () => {
    const data = await AssessmentsAPI.getAvailableAssessments();
    if (data?.results && isArray(data?.results)) {
      setAvailableAssessments([...data?.results]);
    }
  };

  const handleGetAssessments = async () => {
    const query = `?subject=${patientId}&category=assessment-response`;
    const data = await AssessmentsAPI.getAssessments(query);
    if (data && isArray(data) && data.length > 0) {
      setAssessments([...data]);
    }
  };

  const handleAssessmentClick = async (data) => {
    if (data && data?.id) {
      console.log("DEBUG assessment clicked: ", data);
      history.push(`/patient/${patientId}/file/${data?.id}/file`);
    }
  };

  const selectedAssessmentUrl = useMemo(() => {
    if (!selectedAssessment) return null;
    const url = `${selectedAssessment.url}&patientUUID=${patientId}`;
    return url;
  }, [selectedAssessment, patientId]);

  useEffect(() => {
    if (patientId) {
      handleGetAvailableAssessments();
      handleGetAssessments();
    }
  }, [patientId]);

  return (
    <div className={styles.documentViewer}>
      {assessmentSelectionDialogOpen ? (
        <AssessmentSelectionDialog
          assessments={availableAssignments}
          open={assessmentSelectionDialogOpen}
          handleOpenAssessment={handleOpenNewAssessment}
          handleClose={handleNewAssessmentSelectionDialogClose}
          selectedAssessment={selectedAssessment}
          setSelectedAssessment={setSelectedAssessment}
        />
      ) : null}

      {openNewAssessment && selectedAssessmentUrl ? (
        <NewAssessmentDialog
          open={openNewAssessment}
          handleClose={handleCloseNewAssessment}
          assessmentId={1}
          token={"tokenTest"}
          url={selectedAssessmentUrl}
        />
      ) : null}

      <AssessmentsActions>
        {availableAssignments && availableAssignments?.length > 0 ? (
          <>
            <AssessmentsActionButton
              text="New Assessment"
              action={handleAssessmentSelectionDialogClick}
            />
            {/* <AssessmentsActionButton
              text="New Assessment on New Tab"
              action={handleNewAssessmentNewTabClick}
            /> */}
          </>
        ) : null}
      </AssessmentsActions>
      <AssessmentsList
        showAllAssessments={false}
        assessments={assessments}
        onAssessmentClick={handleAssessmentClick}
      />
    </div>
  );
};

export default withRouter(AssessmentsViewer);
